import storageService from './storageService';
import { ICustomerData } from '../model/ICustomerData';
import sessionStorageService from './sessionStorageService';
import IDimensions from '../model/IDimensions';

// We are versioning the customer data in frontend, because if we do it on the backend, then every other application
// which uses customer data needs to be updated. e.g. fin calc, my account, checkouts, basket, etc.
export const CUSTOMER_STORAGE: string = 'customer_data_';
export const CUSTOMER_STORAGE_VERSION_NEW = 'v4';
export const CUSTOMER_STORAGE_VERSION_OLD = 'v3';
export const CUSTOMER_STORAGE_SHARED = 'shared';

export const customerStorage = {
  getOldKey(): string {

    return `${CUSTOMER_STORAGE_VERSION_OLD}_${CUSTOMER_STORAGE}`;
  },

  getKey(dimensions?: IDimensions): string {
    if(dimensions) {
      return `${dimensions.brand}_${dimensions.country}_${CUSTOMER_STORAGE_VERSION_NEW}_${CUSTOMER_STORAGE}`;
    } else {
      return `${CUSTOMER_STORAGE_VERSION_NEW}_${CUSTOMER_STORAGE}`;
    }
  },

  getSharedKey(dimensions?: IDimensions): string {

    return `${dimensions.brand}_${dimensions.country}_${CUSTOMER_STORAGE_SHARED}_${CUSTOMER_STORAGE}`
  },

  getCustomerData(dimensions?: IDimensions, isShared?: boolean): ICustomerData {
    let customerData: ICustomerData | null = null;
    try {
      if (isShared) {
        customerData = JSON.parse(storageService.get(customerStorage.getSharedKey(dimensions)) ?? '');
        storageService.set(customerStorage.getKey(dimensions), JSON.stringify(customerData));
      } else {
        customerData = JSON.parse(storageService.get(customerStorage.getKey(dimensions)) ?? '');
      }
    } catch (error) {
      customerData = null;
    }
    return customerData ?? { postcode: '' };
  },
  
  getCustomerDataSession(dimensions?: IDimensions): ICustomerData {
    let customerData: ICustomerData | null = null;
    try {
      customerData = JSON.parse(sessionStorageService.get(customerStorage.getKey(dimensions)) ?? '');
    } catch (error) {
      customerData = null;
    }
    return customerData ?? { postcode: '' };
  },

  setCustomerData(customerData: ICustomerData, dimensions?: IDimensions, isShared?: boolean): void {
    if (isShared) {
        storageService.set(customerStorage.getKey(dimensions), JSON.stringify(customerData));
        return storageService.set(customerStorage.getSharedKey(dimensions), JSON.stringify(customerData))
    }
    return storageService.set(customerStorage.getKey(dimensions), JSON.stringify(customerData));
  },

  setCustomerDataSession(customerData: ICustomerData, dimensions?: IDimensions): void {
    return sessionStorageService.set(customerStorage.getKey(dimensions), JSON.stringify(customerData));
  },

  removeCustomerData(dimensions?: IDimensions): void {
    return storageService.remove(customerStorage.getKey(dimensions));
  },

  removeOldCustomerData(): void {
    return storageService.remove(customerStorage.getOldKey());
  },

  setPostcode(postcode: string, dimensions?: IDimensions): void {
    const customerData: ICustomerData | null = this.getCustomerData(dimensions);

    if (customerData && customerData.postcode) {
      return;
    } else if (!customerData || !customerData.postcode || !customerData.selectedDealer) {
      this.setCustomerData({ ...customerData, postcode });
    }
  },

  getPostcode(dimensions?: IDimensions): string | null {
    const customerData: ICustomerData | null = this.getCustomerData(dimensions);

    return customerData?.postcode
  }
};

export const getDimensionalCustomerStorage = (dimensions: IDimensions, isShared?: boolean) => {
  return {
    ...customerStorage,
    getKey: () => customerStorage.getKey(dimensions),
    getCustomerData: () => customerStorage.getCustomerData(dimensions, isShared),
    getCustomerDataSession: () => customerStorage.getCustomerDataSession(dimensions),
    setCustomerData: (customerData: ICustomerData) => customerStorage.setCustomerData(customerData, dimensions, isShared),
    setCustomerDataSession: (customerData: ICustomerData) =>
      customerStorage.setCustomerDataSession(customerData, dimensions),
    removeCustomerData: () => customerStorage.removeCustomerData(dimensions),
    removeOldCustomerData: () => customerStorage.removeOldCustomerData(),
    setPostcode: (postcode: string) => customerStorage.setPostcode(postcode, dimensions),
    getPostcode: () => customerStorage.getPostcode(dimensions),
  };
}
