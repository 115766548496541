export default [
  // TODO add markets here
  {
    brand: "sub-dis",
    country: "au",
    region: "au",
    language: "en-au",
  },
  {
    brand: "toy-dis",
    country: "sg",
    region: "au",
    language: "en-sg",
  },
  {
    brand: "toy-dis",
    country: "hk",
    region: "au",
    language: "zh-hk",
  },
  {
    brand: "toy-dis",
    country: "hk",
    region: "au",
    language: "en-hk",
  },
  {
    brand: "lre-dis",
    country: "fi",
    region: "eu",
    language: "fi-fi",
  },
  {
    brand: "lre-dis",
    country: "pl",
    region: "eu",
    language: "pl-pl",
  },
  {
    brand: "jag-dis",
    country: "fi",
    region: "eu",
    language: "fi-fi",
  },
  {
    brand: "jag-dis",
    country: "pl",
    region: "eu",
    language: "pl-pl",
  },
  {
    brand: "lre-dis",
    country: "lt",
    region: "eu",
    language: "lt-lt",
  },
  {
    brand: "lre-dis",
    country: "lv",
    region: "eu",
    language: "lv-lv",
  },
  {
    brand: "bmw-dis",
    country: "cl",
    region: "latam",
    language: "es-cl",
  },
  {
    brand: "bmw-dis",
    country: "pe",
    region: "latam",
    language: "es-pe",
  },
  {
    brand: "lre-dis",
    country: "ee",
    region: "eu",
    language: "et-ee",
  },
  {
    brand: "gly-dis",
    country: "cl",
    region: "latam",
    language: "es-cl",
  },
  {
    brand: "sub-dis",
    country: "cl",
    region: "latam",
    language: "es-cl",
  },
  {
    brand: "jag-dis",
    country: "lt",
    region: "eu",
    language: "lt-lt",
  },
  {
    brand: "jag-dis",
    country: "lv",
    region: "eu",
    language: "lv-lv",
  },
  {
    brand: "jag-dis",
    country: "ee",
    region: "eu",
    language: "et-ee",
  },
  {
    brand: "toy-dis",
    country: "bn",
    region: "au",
    language: "en-bn",
  },
  {
    brand: "dfs-dis",
    country: "pe",
    region: "latam",
    language: "es-pe",
  },
  {
    brand: "sub-dis",
    country: "nz",
    region: "au",
    language: "en-nz",
  },
  {
    brand: "dfs-dis",
    country: "cl",
    region: "latam",
    language: "es-cl",
  },
  {
    brand: "szk-dis",
    country: "pa",
    region: "latam",
    language: "es-pa",
  },
  {
    brand: "szk-dis",
    country: "cr",
    region: "latam",
    language: "es-cr",
  },
  {
    brand: "lex-dis",
    country: "bn",
    region: "au",
    language: "en-bn",
  },
  {
    brand: "sub-dis",
    country: "cl",
    region: "latam",
    language: "es-cl",
  },
  {
    brand: "grw-dis",
    country: "hk",
    region: "au",
    language: "en-hk",
  },
  {
    brand: "grw-dis",
    country: "hk",
    region: "au",
    language: "zh-hk",
  },
  {
    brand: "mini-dis",
    country: "cl",
    region: "latam",
    language: "es-cl",
  },
  {
    brand: "mini-dis",
    country: "pe",
    region: "latam",
    language: "es-pe",
  },
  {
    brand: "mot-dis",
    country: "cl",
    region: "latam",
    language: "es-cl",
  },
  {
    brand: "mer-dis",
    country: "co",
    region: "latam",
    language: "es-co",
  },
  {
    brand: "mer-pas",
    country: "ph",
    region: "au",
    language: "en-ph",
  },
  {
    brand: "cha-dis",
    country: "ph",
    region: "au",
    language: 'en-ph',
  },
  {
    brand: "subaru",
    country: "ar",
    region: "latam",
    language: "es-ar",
  },
  {
    brand: "hin-dis",
    country: "co",
    region: "latam",
    language: "es-co",
  },
  {
    brand: "mot-dis",
    country: "pe",
    region: "latam",
    language: "es-pe",
  },
  {
    brand: "lex-dis",
    country: "sg",
    region: "au",
    language: "en-sg",
  },
  {
    brand: "szk-dis",
    country: "sg",
    region: "au",
    language: "en-sg",
  },
  {
    brand: "subaru",
    country: "pe",
    region: "latam",
    language: "es-pe",
  },
  {
    brand: "subaru",
    country: "co",
    region: "latam",
    language: "es-co",
  },
  {
    brand: "lex-dis",
    country: "hk",
    region: "au",
    language: "en-hk",
  },
  {
    brand: "byd-dis",
    country: "be",
    region: "eu",
    language: "nl-be",
  },
  {
    brand: "byd-dis",
    country: "be",
    region: "eu",
    language: "fr-be",
  },
  {
    brand: "byd-dis",
    country: "be",
    region: "eu",
    language: "en-be",
  },
  {
    brand: "mer-pas",
    country: "ec",
    region: "latam",
    language: "es-ec",
  },
  {
    brand: "gwm-dis",
    country: "cl",
    region: "latam",
    language: "es-cl",
  },
  {
    brand: "mer-pas",
    country: "id",
    region: "au",
    language: "en-id",
  },
];
